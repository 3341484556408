import { useState, useEffect } from 'react';
import axios from 'axios';

export default function Init() {
  const [cursors, setCursors] = useState([{ username: 'qwerty', color: '#0000FF', x: 150, y: 250, lockId: null }]);
  const [myCursor, setMyCursor] = useState(null);
  const [myPreviousCursor, setMyPreviousCursor] = useState(null);
  const [syncStates, setSyncStates] = useState(null);

  // const apiEndpoint = 'http://localhost:3001/api/cursors';
  const apiEndpoint = 'https://sockapidev1.thecatalyst.dev/api/cursors';

  const urlParams = new URLSearchParams(window.location.search);
  const username = urlParams.get('username') || 'qwerty';
  const room = urlParams.get('room') || 'default';
  const color = `#${urlParams.get('color') || '#0000FF'}`.replace('##', '#');
  const syncInterval = Math.max(urlParams.get('refreshRate') || 1000, 200);
  const pauseInterval =  Math.min(urlParams.get('pauseRate') || 10000, 20000);

  const getMyCursor = () => {
    return cursors.find((cursor) => cursor?.username === username);
  };

  const getOtherCursors = () => {
    return cursors.filter((cursor) => cursor?.username !== username);
  };

  const syncCursors = async () => {
    try {
      if (
        JSON.stringify(myCursor || {})
          .trim()
          .toLowerCase() !==
        JSON.stringify(myPreviousCursor || {})
          .trim()
          .toLowerCase()
      ) {
        const response = await axios.put(apiEndpoint, myCursor);
        setCursors([...(response?.data?.data || [])]);
        setMyPreviousCursor(myCursor);
      } else {
        if (new Date() - new Date(getMyCursor()?.lastAction) <= pauseInterval) {
          const response = await axios.put(apiEndpoint, { room: myCursor.room });
          setCursors([...(response?.data?.data || [])]);
          setMyPreviousCursor(myCursor);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMyCursor(() => ({
        ...getMyCursor(),
        username,
        room,
        color,
        x: event.clientX,
        y: event.clientY,
      }));
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSyncStates(new Date());
    }, syncInterval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    syncCursors();
  }, [syncStates]);

  return (
    <div className="w-screen h-screen cursor-none overflow-hidden">
      {cursors && <div className="absolute z-[1] pointer-events-none text-wrap">Stats: {JSON.stringify(cursors)}</div>}
      <div className="w-full h-full bg-gray-200 relative">
        {getOtherCursors().map(
          (cursor) =>
            cursor && (
              <div key={cursor?.username} className="absolute" style={{ left: cursor?.x, top: cursor?.y }}>
                <div className="w-0 h-0 border-t-8 border-r-8 border-b-8 border-l-8 border-transparent" style={{ borderTopColor: cursor.color, transform: 'rotate(45deg)' }}></div>
                <div className="text-xs mt-2" style={{ color: cursor.color }}>
                  {cursor?.username}
                </div>
              </div>
            )
        )}
        {myCursor && (
          <div className="absolute" style={{ left: myCursor?.x, top: myCursor?.y }}>
            <div className="w-0 h-0 border-t-8 border-r-8 border-b-8 border-l-8 border-transparent" style={{ borderTopColor: myCursor?.color, transform: 'rotate(45deg)' }}></div>
            <div className="text-xs mt-2" style={{ color: myCursor?.color }}>
              {myCursor?.username}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
